import md5 from "crypto-js/md5";
import { useState } from "react";

import { Article } from "Components/Article";
import { Input } from "Components/Input";
import { MonacoInput } from "Components/MonacoEditor";

export interface IMd5State {
	input: string;
	hash: string;
}

function getState(input: string): IMd5State {
	return {
		hash: md5(input).toString(),
		input
	};
}

export function Md5Hashing() {
	const [state, setState] = useState(getState(""));

	return (
		<article>
			<Article.Header>
				<Article.Headline>MD5 hashing</Article.Headline>
				<Article.Actions>
					<button className="btn btn-tinted" onClick={() => setState(getState(""))}>Clear</button>
				</Article.Actions>
			</Article.Header>
			<Input id="md5-hash" type="text"
				label="Result"
				value={state.hash}
				copyToClipboard={true}
				readOnly={true}
			/>
			<MonacoInput id="md5-input"
				label="Input"
				language="plaintext"
				value={state.input}
				onChange={input => setState(getState(input))}
			/>
		</article>
	);
}
