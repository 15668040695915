import { useEffect, useState } from "react";

import { KeyValue, KeyValueList } from "Tools/Components/KeyValueList";

function useHighEntropyValues(): KeyValue[] {
	const [state, setState] = useState<IHighEntropyValues>({ });

	useEffect(() => {
		if (!navigator.userAgentData) {
			return;
		}

		const uad = navigator.userAgentData;

		async function getValues() {
			const values = await uad.getHighEntropyValues([
				"architecture",
				"bitness",
				"model",
				"platform",
				"platformVersion",
				"uaFullVersion"
			]);

			setState(values);
		}

		void getValues();
	}, []);

	const keyValues: KeyValue[] = [];

	for (const name of Object.getOwnPropertyNames(state)) {
		if (name === "brands") {
			continue;
		}

		const value = state[name as keyof IHighEntropyValues]?.toString();

		if (!value) {
			continue;
		}

		keyValues.push([
			name,
			name,
			value
		]);
	}

	return keyValues;
}

export function ClientHints() {
	const highEntropyValues = useHighEntropyValues();

	if (!navigator.userAgentData) {
		return (
			<article>
				<h2>User Agent Client Hints</h2>
				<p>Not supported.</p>
			</article>
		);
	}

	return (
		<article>
			<h2 className="mb-3">User-Agent Client Hints</h2>
			<p>{navigator.userAgentData.mobile ? "Mobile browser." : "Non mobile browser."}</p>
			<h3 className="mt-4">Brands</h3>
			<table className="table table-bordered table-striped" style={{ minWidth: "30%", width: "auto" }}>
				<thead>
					<tr>
						<th>Brand</th>
						<th>Version</th>
					</tr>
				</thead>
				<tbody>
					{navigator.userAgentData.brands.map(brand => (
						<tr key={`${brand.brand}:${brand.version}`}>
							<td><pre className="mb-0">{brand.brand}</pre></td>
							<td><pre className="mb-0">{brand.version}</pre></td>
						</tr>
					))}
				</tbody>
			</table>
			<h3 className="mt-4">High entropy values</h3>
			<KeyValueList keyValues={highEntropyValues} />
		</article>
	);
}
