import { useEffect, useState } from "react";

export function useInterval<T>(interval: number, valueFunction: () => T) {
	const [value, setValue] = useState(valueFunction());
	useEffect(() => {
		const id = setInterval(() => setValue(valueFunction()), interval);

		return () => clearInterval(id);
	}, [interval, valueFunction]);

	return value;
}
