import { dump, load } from "js-yaml";
import { useId, useState } from "react";

import { Article } from "Components/Article";
import { MonacoInput } from "Components/MonacoEditor";
import { Encoder } from "Tools/Encoding/Encoders";

const encoder = new Encoder(
	yamlStr => JSON.stringify(load(yamlStr, { onWarning: () => { /* */ } }) ?? {}, null, 2),
	jsonStr => dump(JSON.parse(jsonStr))
);

export function YamlConversion() {
	const [result, setResult] = useState(encoder.encode(""));
	const yamlId = useId();
	const jsonId = useId();

	return (
		<article>
			<Article.Header>
				<Article.Headline>YAML to JSON</Article.Headline>
				<Article.Actions>
					<button className="btn btn-tinted" onClick={() => setResult(encoder.encode(""))}>Clear</button>
				</Article.Actions>
			</Article.Header>
			<MonacoInput id={yamlId}
				label="YAML"
				language="yaml"
				value={result.decoded}
				copyToClipboard={true}
				onChange={value => setResult(encoder.encode(value, result.encoded))}
			/>
			<MonacoInput id={jsonId}
				label="JSON"
				language="json"
				value={result.encoded}
				invalid={result.encodingError}
				wordWrap={true}
				copyToClipboard={true}
				onChange={value => setResult(encoder.decode(value, result.decoded))}
			/>
		</article>
	);
}
