import { KeyValue, KeyValueList } from "Tools/Components/KeyValueList";
import { IUserAddress, useUserAddress } from "Tools/Hooks/UserAddress";

function getHostnameText(userAddress: IUserAddress) {
	switch (userAddress.hostnameState) {
		case "failed": return "could not resolve";
		case "resolving": return "resolving..";
		case "resolved": return userAddress.hostname;
		default: return "";
	}
}

export function UserAddress() {
	const userAddress = useUserAddress();

	const keyValues: KeyValue[] = [
		["ip", "IP", userAddress.ip],
		["hostname", "Hostname", getHostnameText(userAddress)]
	];

	return (
		<article>
			<h2>Your address</h2>
			<KeyValueList keyValues={keyValues} />
		</article>
	);
}
