import { createRoot } from "react-dom/client";

import { ToolsMenu } from "./ToolsMenu";
import { IToolsData, ToolsContext } from "./ToolsModel";

const toolsElement = document.getElementById("tools");
const toolsDataElement = document.getElementById("tools-data");

if (toolsElement != null && toolsDataElement != null) {
	const toolsData = JSON.parse(toolsDataElement.textContent ?? "{}") as IToolsData;
	const root = createRoot(toolsElement);

	self.MonacoEnvironment = {
		createTrustedTypesPolicy() {
			return undefined;
		},
		getWorkerUrl(_moduleId: string, label: string) {
			if (label === "html") {
				return toolsData.monacoHtmlWorkerUrl;
			}

			if (label === "json") {
				return toolsData.monacoJsonWorkerUrl;
			}

			return toolsData.monacoEditorWorkerUrl;
		}
	};

	root.render(
		<ToolsContext.Provider value={toolsData}>
			<ToolsMenu />
		</ToolsContext.Provider>
	);
}
