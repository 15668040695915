import { createContext, useContext } from "react";

export interface IToolsData {
	monacoEditorWorkerUrl: string;
	monacoHtmlWorkerUrl: string;
	monacoJsonWorkerUrl: string;
	requestHeaders: string[][];
	userHost: string;
	userIp: string;
}

export const ToolsContext = createContext<IToolsData >({
	monacoEditorWorkerUrl: "",
	monacoHtmlWorkerUrl: "",
	monacoJsonWorkerUrl: "",
	requestHeaders: [],
	userHost: "",
	userIp: ""
});

export function useToolsData() {
	return useContext(ToolsContext);
}
