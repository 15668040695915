import base64 from "crypto-js/enc-base64";
import utf8 from "crypto-js/enc-utf8";
import { QRCodeErrorCorrectionLevel, toString } from "qrcode";
import { useEffect, useRef, useState } from "react";

import { Article } from "Components/Article";
import { MonacoInput } from "Components/MonacoEditor";

function getMaxLength(errorCorrectionLevel: QRCodeErrorCorrectionLevel) {
	switch (errorCorrectionLevel) {
		case "L":
		case "low":
			return 2953;

		case "M":
		case "medium":
			return 2331;

		case "Q":
		case "quartile":
			return 1663;

		case "H":
		case "high":
			return 1273;
	}

	return undefined;
}

export function QrEncoding() {
	const imgRef = useRef<HTMLImageElement>(null);
	const [errorCorrectionLevel, setErrorCorrectionLevel] = useState<QRCodeErrorCorrectionLevel>("medium");
	const [text, setText] = useState("");
	const maxLength = getMaxLength(errorCorrectionLevel);

	useEffect(() => {
		async function updateImage() {
			if (imgRef.current && text) {
				const svg = await toString(text, { errorCorrectionLevel, margin: 3, type: "svg" });
				imgRef.current.src = "data:image/svg+xml;base64," + base64.stringify(utf8.parse(svg));
			}
		}

		void updateImage();
	}, [imgRef, errorCorrectionLevel, text]);

	return (
		<article>
			<Article.Header>
				<Article.Headline>QR code</Article.Headline>
				<Article.Actions>
					<button className="btn btn-tinted" onClick={() => setText("")}>Clear</button>
				</Article.Actions>
			</Article.Header>
			<div className="d-lg-flex gap-4">
				<div className="flex-grow-1" style={{ minWidth: 0 }}>
					<div className="mb-3">
						<label className="form-label" htmlFor="qr-ecl">Error correction level</label>
						<select className="form-select" id="qr-ecl" value={errorCorrectionLevel} onChange={e => setErrorCorrectionLevel(e.target.value as QRCodeErrorCorrectionLevel)}>
							<option value="low">Low (max {getMaxLength("low")} bytes)</option>
							<option value="medium">Medium (max {getMaxLength("medium")} bytes)</option>
							<option value="quartile">Quartile (max {getMaxLength("quartile")} bytes)</option>
							<option value="high">High (max {getMaxLength("high")} bytes)</option>
						</select>
					</div>
					<MonacoInput id="qrcoding-text"
						label="Text to encode"
						language="plaintext"
						value={text}
						invalid={maxLength && text.length > maxLength}
						onChange={value => setText(value)}
					/>
				</div>
				<div style={{ minWidth: 400 }}>
					<div className={text ? "d-block" : "d-none"}>
						<img className="mb-2" ref={imgRef}></img>
						<p>{text.length} bytes</p>
					</div>
				</div>
			</div>
		</article>
	);
}
