import { useCallback, useReducer } from "react";

import { Article } from "Components/Article";
import { Input } from "Components/Input";

interface IBaseState {
	binary: string;
	decimal: string;
	hexadecimal: string;
	value: number;
}

type BaseAction =
	{ type: "Decrement" } |
	{ type: "Increment" } |
	{ type: "UpdateBinary"; binary: string } |
	{ type: "UpdateDecimal"; decimal: string } |
	{ type: "UpdateHexadecimal"; hexadecimal: string }
;

function getState(number: number): IBaseState {
	return {
		binary: number.toString(2),
		decimal: number.toString(10),
		hexadecimal: number.toString(16),
		value: number
	};
}

function baseReducer(state: IBaseState, action: BaseAction): IBaseState {
	switch (action.type) {
		case "Decrement":
			return getState(state.value - 1);

		case "Increment":
			return getState(state.value + 1);

		case "UpdateBinary": {
			const num = parseInt(action.binary ? action.binary : "0", 2);

			return {
				...getState(num),
				binary: action.binary
			};
		}

		case "UpdateDecimal": {
			const num = parseInt(action.decimal ? action.decimal : "0", 10);

			return {
				...getState(num),
				decimal: action.decimal
			};
		}

		case "UpdateHexadecimal": {
			const num = parseInt(action.hexadecimal ? action.hexadecimal : "0", 16);

			return {
				...getState(num),
				hexadecimal: action.hexadecimal
			};
		}

		default:
			return state;
	}
}

export function BaseConversion() {
	const [state, dispatch] = useReducer(baseReducer, 0, getState);
	const keyboardCallback = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		switch (e.key) {
			case "ArrowUp":
				dispatch({ type: "Increment" });
				break;

			case "ArrowDown":
				dispatch({ type: "Decrement" });
				break;
		}
	}, []);

	return (
		<article>
			<Article.Header>
				<Article.Headline>Number bases</Article.Headline>
				<Article.Actions>
					<button className="btn btn-tinted" onClick={() => dispatch({ decimal: "0", type: "UpdateDecimal" })}>Reset</button>
				</Article.Actions>
			</Article.Header>
			<div className="row">
				<div className="col-lg-4">
					<Input id="baseconv-binary" type="text"
						label="Binary number"
						value={state.binary}
						pattern="^-?[01]*$"
						onChange={binary => dispatch({ binary, type: "UpdateBinary" })}
						onKeyDown={keyboardCallback}
					/>
				</div>
				<div className="col-lg-4">
					<Input id="baseconv-decimal" type="number"
						label="Decimal number"
						value={state.decimal}
						pattern="^-?[0-9]*$"
						onChange={decimal => dispatch({ decimal, type: "UpdateDecimal" })}
					/>
				</div>
				<div className="col-lg-4">
					<Input id="baseconv-hex" type="text"
						label="Hexadecimal number"
						value={state.hexadecimal}
						pattern="^-?[0-9a-f]*$"
						onChange={hexadecimal => dispatch({ hexadecimal, type: "UpdateHexadecimal" })}
						onKeyDown={keyboardCallback}
					/>
				</div>
			</div>
		</article>
	);
}
