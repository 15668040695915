﻿export class ExtendedDate {
	private readonly date = new Date();

	public constructor(input: Date | number | string | null = null) {
		if (input instanceof Date) {
			this.date = input;
		} else if (input != null) {
			this.date = new Date(input);
		}
	}

	public toISOString(): string {
		return this.date.toISOString();
	}

	public toReadableIso(): string {
		const month = `${this.date.getMonth() + 1 < 10 ? "0" : ""}${this.date.getMonth() + 1}`;
		const day = `${this.date.getDate() < 10 ? "0" : ""}${this.date.getDate()}`;
		const date = `${this.date.getFullYear()}-${month}-${day}`;
		const time = this.date.toTimeString().substring(0, 8);

		return date + " " + time;
	}

	public toUnixTime(): number {
		return Math.round(this.date.getTime() / 1000);
	}

	public static fromFormatted(input: string | null): ExtendedDate | null {
		if (input == null || input === "") {
			return null;
		}

		try {
			const result = new ExtendedDate(input);
			if (!isNaN(result.date.getTime())) {
				return result;
			}
		} catch (error: unknown) {
			console.error(error);
		}

		return null;
	}

	public static fromUnixTime(input: string | null): ExtendedDate | null {
		if (input == null || input === "") {
			return null;
		}

		try {
			const unixtime = parseInt(input, 10);
			const time = new Date();
			time.setTime(unixtime * 1000);

			const result = new ExtendedDate(time);
			if (!isNaN(result.date.getTime())) {
				return result;
			}
		} catch (error: unknown) {
			console.error(error);
		}

		return null;
	}
}
