import { KeyValue, KeyValueList } from "Tools/Components/KeyValueList";
import { useViewport } from "Tools/Hooks/Viewport";

function Key(props: { label: string; properties: string[] }) {
	return <span>
		<strong>{props.label}</strong>
		<ul className="list-unstyled">
			{props.properties.map(p => <li key={p}>{p}</li>)}
		</ul>
	</span>;
}

export function Viewport() {
	const viewport = useViewport();

	const keyValues: KeyValue[] = [
		[
			"dpr",
			{ node: <Key key="dpr" label="Device pixel ratio" properties={["devicePixelRatio"]} />, text: "Device pixel ratio" },
			`${viewport.devicePixelRatio}`
		],
		[
			"is",
			{ node: <Key key="is" label="Inner size" properties={["innerWidth", "innerHeight"]} />, text: "Inner size" },
			`${viewport.innerWidth} x ${viewport.innerHeight} px`
		],
		[
			"os",
			{ node: <Key key="os" label="Outer size" properties={["outerWidth", "outerHeight"]} />, text: "Outer size" },
			`${viewport.outerWidth} x ${viewport.outerHeight} px`
		],
		[
			"ss",
			{ node: <Key key="ss" label="Screen size" properties={["screen.width", "screen.height"]} />, text: "Screen size" },
			`${viewport.screen.width} x ${viewport.screen.height} px`
		]
	];

	return (
		<article>
			<h2 className="mb-3">Browser viewport</h2>
			<KeyValueList keyValues={keyValues} />
		</article>
	);
}
