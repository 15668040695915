import { useReducer } from "react";

import { Article } from "Components/Article";
import { Input } from "Components/Input";
import { ExtendedDate } from "Tools/ExtendedDate";
import { useInterval } from "Tools/Hooks/Time";

export interface ITimeState {
	iso: string;
	local: string;
	unix: string;
}

type TimeAction =
	{ type: "UpdateISO"; iso: string } |
	{ type: "UpdateLocal"; local: string } |
	{ type: "UpdateUnix"; unix: string }
;

function getState(time: ExtendedDate): ITimeState {
	return {
		iso: time.toISOString(),
		local: time.toReadableIso(),
		unix: time.toUnixTime().toString()
	};
}

function timeReducer(state: ITimeState, action: TimeAction): ITimeState {
	switch (action.type) {
		case "UpdateISO": {
			const date = ExtendedDate.fromFormatted(action.iso);

			return {
				...(date ? getState(date) : state),
				iso: action.iso
			};
		}

		case "UpdateLocal": {
			const date = ExtendedDate.fromFormatted(action.local);

			return {
				...(date ? getState(date) : state),
				local: action.local
			};
		}

		case "UpdateUnix": {
			const date = ExtendedDate.fromUnixTime(action.unix);

			return {
				...(date ? getState(date) : state),
				unix: action.unix
			};
		}

		default:
			return state;
	}
}

export function TimeConversion() {
	const time = useInterval(1000, () => new ExtendedDate());
	const [state, dispatch] = useReducer(timeReducer, time, getState);

	return (
		<article>
			<Article.Header>
				<Article.Headline>Time formats</Article.Headline>
				<Article.Actions>
					<button className="btn btn-tinted" onClick={() => dispatch({ iso: new ExtendedDate().toISOString(), type: "UpdateISO" })}>Reset</button>
				</Article.Actions>
			</Article.Header>
			<div className="row">
				<div className="col-lg mb-4 mb-lg-0">
					<p className="text-muted">Convert between time formats.</p>
					<Input id="timeconv-iso8601" type="text"
						label="ISO 8601"
						value={state.iso}
						onChange={iso => dispatch({ iso, type: "UpdateISO" })}
					/>
					<Input id="timeconv-local" type="text"
						label="Local"
						value={state.local}
						onChange={local => dispatch({ local, type: "UpdateLocal" })}
					/>
					<Input id="timeconv-unix" type="number"
						label="Unix"
						value={state.unix}
						onChange={unix => dispatch({ type: "UpdateUnix", unix })}
					/>
				</div>
				<div className="col-lg">
					<p className="text-muted">Current time in different formats.</p>
					<Input id="timeconv-current-iso8601" type="text"
						label="Current ISO 8601"
						value={time.toISOString()}
						readOnly={true}
					/>
					<Input id="timeconv-current-local" type="text"
						label="Current local"
						value={time.toReadableIso()}
						readOnly={true}
					/>
					<Input id="timeconv-current-unix" type="number"
						label="Current Unix"
						value={time.toUnixTime().toString()}
						readOnly={true}
					/>
				</div>
			</div>
		</article>
	);
}
