import { query } from "jsonpath";
import { useState } from "react";

import { Article } from "Components/Article";
import { Input } from "Components/Input";
import { MonacoInput } from "Components/MonacoEditor";

export interface IJsonDataState {
	jsonInput: string;
	jsonInputValid: boolean;
	jsonPath: string;
	jsonPathValid: boolean;
	jsonResult: string;
}

const blankState: IJsonDataState = {
	jsonInput: "{}",
	jsonInputValid: true,
	jsonPath: "",
	jsonPathValid: true,
	jsonResult: "{}"
};

function tryParse(json: string) {
	try {
		return JSON.parse(json) as unknown;
	} catch {
		return null;
	}
}

function evaluateJsonPath(object: unknown, jsonPath: string): unknown {
	if (!jsonPath) {
		return object;
	}

	try {
		const result = query(object, jsonPath);

		if (result.length === 1) {
			return result[0];
		}

		return result as unknown;
	} catch {
		return null;
	}
}

function getState(jsonInput: string, jsonPath: string): Partial<IJsonDataState> {
	const parsedObject = tryParse(jsonInput);
	if (parsedObject == null) {
		return {
			jsonInput,
			jsonInputValid: false
		};
	}

	const evaluatedObject = evaluateJsonPath(parsedObject, jsonPath);
	if (evaluatedObject == null) {
		return {
			jsonInput,
			jsonInputValid: true,
			jsonPath,
			jsonPathValid: false
		};
	}

	return {
		jsonInput,
		jsonInputValid: true,
		jsonPath,
		jsonPathValid: true,
		jsonResult: JSON.stringify(evaluatedObject, null, 2)
	};
}

export function JsonData() {
	const [state, setState] = useState<IJsonDataState>(blankState);

	return (
		<article>
			<Article.Header>
				<Article.Headline>JSON</Article.Headline>
				<Article.Actions>
					<button className="btn btn-tinted" onClick={() => setState(blankState)}>Clear</button>
				</Article.Actions>
			</Article.Header>
			<MonacoInput id="jsondata-input"
				label="Input JSON"
				language="json"
				value={state.jsonInput}
				invalid={!state.jsonInputValid}
				copyToClipboard={true}
				onChange={json => setState(prev => ({ ...prev, ...getState(json, state.jsonPath) }))}
			/>
			<a href="https://github.com/dchester/jsonpath" className="float-end">Help</a>
			<Input id="jsonpath"
				label="JSON path"
				value={state.jsonPath}
				invalid={!state.jsonPathValid}
				type="text"
				onChange={jsonPath => setState(prev => ({ ...prev, ...getState(state.jsonInput, jsonPath) }))}
			/>
			<MonacoInput id="jsondata-output"
				label="Output JSON"
				language="json"
				value={state.jsonResult}
				copyToClipboard={true}
				readOnly={true}
			/>
		</article>
	);
}
