import base64 from "crypto-js/enc-base64";
import utf8 from "crypto-js/enc-utf8";
import { useState } from "react";

import { Article } from "Components/Article";
import { MonacoInput } from "Components/MonacoEditor";

interface IJwtState {
	invalid: boolean;
	jwt: string;
	header?: string;
	payload?: string;
	signature?: string;
}

const blankState: IJwtState = {
	invalid: false,
	jwt: ""
};

function parsePart(part: string) {
	const json = utf8.stringify(base64.parse(part));

	return JSON.stringify(JSON.parse(json), null, 2);
}

function getState(jwt: string): IJwtState {
	const result = /^(?<header>[^.]*)\.(?<payload>[^.]*)\.(?<signature>[^.]*)$/.exec(jwt);

	if (result?.length !== 4 || !result.groups) {
		return {
			invalid: true,
			jwt
		};
	}

	try {
		return {
			header: parsePart(result.groups.header),
			invalid: false,
			jwt,
			payload: parsePart(result.groups.payload),
			signature: result.groups.signature
		};
	} catch {
		return {
			invalid: true,
			jwt
		};
	}
}

export function JwtInspector() {
	const [state, setState] = useState<IJwtState>(blankState);

	return (
		<article>
			<Article.Header>
				<Article.Headline>JWT</Article.Headline>
				<Article.Actions>
					<button className="btn btn-tinted" onClick={() => setState(blankState)}>Clear</button>
				</Article.Actions>
			</Article.Header>
			<MonacoInput id="jwt-input"
				label="JWT Token"
				language="plaintext"
				value={state.jwt}
				wordWrap={true}
				onChange={jwt => setState(prev => ({ ...prev, ...getState(jwt) }))}
			/>
			<MonacoInput id="jwt-header"
				label="Header"
				language="json"
				value={state.header ?? ""}
				copyToClipboard={true}
				readOnly={true}
			/>
			<MonacoInput id="jwt-payload"
				label="Payload"
				language="json"
				value={state.payload ?? ""}
				copyToClipboard={true}
				readOnly={true}
			/>
		</article>
	);
}
