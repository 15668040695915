import { useMemo } from "react";

import { KeyValue, KeyValueList } from "Tools/Components/KeyValueList";
import { useToolsData } from "Tools/ToolsModel";

export function RequestHeaders() {
	const toolsData = useToolsData();
	const headers = useMemo(
		() => toolsData.requestHeaders.map<KeyValue>(header => [header[0], header[0], header[1]]),
		[toolsData]
	);

	return (
		<article>
			<h2>Browser headers</h2>
			<KeyValueList keyValues={headers} />
		</article>
	);
}
