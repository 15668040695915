import sha1 from "crypto-js/sha1";
import { useState } from "react";

import { Article } from "Components/Article";
import { Input } from "Components/Input";
import { MonacoInput } from "Components/MonacoEditor";

export interface ISha1State {
	input: string;
	hash: string;
}

function getState(input: string): ISha1State {
	return {
		hash: sha1(input).toString(),
		input
	};
}

export function Sha1Hashing() {
	const [state, setState] = useState(getState(""));

	return (
		<article>
			<Article.Header>
				<Article.Headline>SHA-1 hashing</Article.Headline>
				<Article.Actions>
					<button className="btn btn-tinted" onClick={() => setState(getState(""))}>Clear</button>
				</Article.Actions>
			</Article.Header>
			<Input id="sha1-hash" type="text"
				label="Result"
				value={state.hash}
				copyToClipboard={true}
				readOnly={true}
			/>
			<MonacoInput id="sha1-input"
				label="Input"
				language="plaintext"
				value={state.input}
				onChange={input => setState(getState(input))}
			/>
		</article>
	);
}
