import { memo } from "react";

export interface IKeyValueData {
	node?: React.ReactNode;
	text: string;
}

export type KeyValue = [
	id: string,
	key: IKeyValueData | string,
	value: IKeyValueData | string | null
];

export const KeyValueList = memo(function KeyValueList(props: { keyValues: KeyValue[] }) {
	return (
		<table className="table table-sm table-keyvalue table-nomargin">
			<tbody>
				{props.keyValues.map(([id, key, value]) => (
					<tr key={id}>
						<td><span className="key">{typeof key === "string" ? key : key.node ?? key.text}</span></td>
						<td style={{ width: "80%" }}><code className="value">{typeof value === "string" ? value : value?.node ?? value?.text ?? ""}</code></td>
					</tr>
				))}
			</tbody>
		</table>
	);
});
