import { useEffect, useState } from "react";

export interface IViewport {
	devicePixelRatio: number;
	innerHeight: number;
	innerWidth: number;
	outerHeight: number;
	outerWidth: number;
	screen: {
		height: number;
		width: number;
	};
}

function getViewport(): IViewport {
	return {
		devicePixelRatio: window.devicePixelRatio,
		innerHeight: window.innerHeight,
		innerWidth: window.innerWidth,
		outerHeight: window.outerHeight,
		outerWidth: window.outerWidth,
		screen: {
			height: window.screen.height,
			width: window.screen.width
		}
	};
}

export function useViewport() {
	const [viewport, setViewport] = useState(getViewport());

	useEffect(() => {
		function callback() {
			setViewport(getViewport());
		}

		window.addEventListener("orientation", callback);
		window.addEventListener("resize", callback);

		return () => {
			window.removeEventListener("orientation", callback);
			window.removeEventListener("resize", callback);
		};
	}, []);

	return viewport;
}
