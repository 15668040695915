import { useState } from "react";

import { Article } from "Components/Article";
import { MonacoInput } from "Components/MonacoEditor";

import { Encoder } from "./Encoders";

const encoder = new Encoder(JSON.stringify, JSON.parse);

export function StringEncoding() {
	const [result, setResult] = useState(encoder.encode(""));

	return (
		<article>
			<Article.Header>
				<Article.Headline>String encoding</Article.Headline>
				<Article.Actions>
					<button className="btn btn-tinted" onClick={() => setResult(encoder.encode(""))}>Clear</button>
				</Article.Actions>
			</Article.Header>
			<MonacoInput id="urlencoding-plain"
				label="Unencoded string"
				language="plaintext"
				value={result.decoded}
				copyToClipboard={true}
				onChange={value => setResult(encoder.encode(value))}
			/>
			<MonacoInput id="urlencoding-encoded"
				example={"\"\""}
				label="Encoded string"
				language="plaintext"
				value={result.encoded}
				invalid={result.encodingError}
				copyToClipboard={true}
				onChange={value => setResult(encoder.decode(value))}
			/>
		</article>
	);
}
