
export interface IEncoderResult {
	encoded: string;
	encodingError: boolean;
	encodingRoundtrips: boolean;
	decoded: string;
}

export class Encoder {
	public constructor(
		private readonly innerEncode: (input: string) => string,
		private readonly innerDecode: (input: string) => string) {
		// Empty on purpose
	}

	public encode(decoded: string, encodedFallback = ""): IEncoderResult {
		try {
			return {
				decoded,
				encoded: this.innerEncode(decoded),
				encodingError: false,
				encodingRoundtrips: true
			};
		} catch {
			return {
				decoded,
				encoded: encodedFallback,
				encodingError: true,
				encodingRoundtrips: false
			};
		}
	}

	public decode(encoded: string, decodedFallback = ""): IEncoderResult {
		try {
			const decoded = this.innerDecode(encoded);
			const encodedAgain = this.innerEncode(decoded);

			return {
				decoded,
				encoded,
				encodingError: false,
				encodingRoundtrips: encodedAgain === encoded
			};
		} catch {
			return {
				decoded: decodedFallback,
				encoded,
				encodingError: true,
				encodingRoundtrips: false
			};
		}
	}
}

export class HtmlEncoder extends Encoder {
	public constructor() {
		super(input => HtmlEncoder.htmlEncode(input), input => HtmlEncoder.htmlDecode(input));
	}

	private static htmlEncode(html: string) {
		const textNode = document.createTextNode(html);
		const containerNode = document.createElement("a").appendChild(textNode);

		return (containerNode.parentNode as HTMLElement).innerHTML;
	}

	private static htmlDecode(html: string) {
		const a = document.createElement("a");
		a.innerHTML = html;

		return a.textContent ?? html;
	}
}
