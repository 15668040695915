function Header(props: { children?: React.ReactNode }) {
	return (
		<div className="article-header">
			{props.children}
		</div>
	);
}

function Headline(props: { children?: React.ReactNode }) {
	return <h2 className="article-headline">{props.children}</h2>;
}

function Actions(props: { children?: React.ReactNode }) {
	return (
		<div className="article-actions">
			<div className="btn-group w-100">
				{props.children}
			</div>
		</div>
	);
}

export const Article = {
	Actions,
	Header,
	Headline
};
