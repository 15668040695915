import { useEffect, useState } from "react";

import { useToolsData } from "Tools/ToolsModel";

export interface IUserAddress {
	ip: string;
	hostname: string;
	hostnameState: "failed" | "resolved" | "resolving";
}

interface IResolveHostnameResponse {
	hostname: string;
}

export function useUserAddress(): IUserAddress {
	const toolsData = useToolsData();
	const [userAddress, setUserAddress] = useState<IUserAddress>(() => {
		return {
			hostname: toolsData.userHost,
			hostnameState: toolsData.userHost === "" ? "resolving" : "resolved",
			ip: toolsData.userIp
		};
	});

	async function resolveHostname() {
		try {
			const response = await fetch("/tools/resolvehostname");
			const result = await response.json() as IResolveHostnameResponse;

			setUserAddress({
				hostname: result.hostname,
				hostnameState: result.hostname && result.hostname.length > 0 ? "resolved" : "failed",
				ip: toolsData.userIp
			});
		} catch {
			setUserAddress({
				hostname: "",
				hostnameState: "failed",
				ip: toolsData.userIp
			});
		}
	}

	useEffect(() => {
		if (toolsData.userHost === "") {
			void resolveHostname();
		}
	}, [toolsData]);

	return userAddress;
}
