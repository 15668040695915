import base64 from "crypto-js/enc-base64";
import utf8 from "crypto-js/enc-utf8";
import { useState } from "react";

import { Article } from "Components/Article";
import { MonacoInput } from "Components/MonacoEditor";

import { Encoder } from "./Encoders";

const normalEncoder = new Encoder(
	plaintext => base64.stringify(utf8.parse(plaintext)),
	encoded => utf8.stringify(base64.parse(encoded))
);

const urlSafeEncoder = new Encoder(
	plaintext => {
		return base64.stringify(utf8.parse(plaintext)).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
	},
	encoded => {
		const base64data = encoded.replace(/-/g, "+").replace(/_/g, "/") + "===".slice((encoded.length + 3) % 4);

		return utf8.stringify(base64.parse(base64data));
	}
);

export function Base64Encoding() {
	const [urlSafe, setUrlSafe] = useState(false);
	const encoder = urlSafe ? urlSafeEncoder : normalEncoder;
	const otherEncoder = !urlSafe ? urlSafeEncoder : normalEncoder;
	const [result, setResult] = useState(() => encoder.encode(""));

	return (
		<article>
			<Article.Header>
				<Article.Headline>Base64 encoding</Article.Headline>
				<Article.Actions>
					<button className="btn btn-tinted" onClick={() => setResult(encoder.encode(""))}>Clear</button>
				</Article.Actions>
			</Article.Header>
			<div className="form-check">
				<input
					id="url-safe-encoding"
					type="checkbox"
					className="form-check-input"
					checked={urlSafe}
					onChange={() => { setUrlSafe(!urlSafe); setResult(otherEncoder.encode(result.decoded)); }}
				/>
				<label
					htmlFor="url-safe-encoding"
					className="form-label"
				>
					Use Base64URL-encoding
				</label>
			</div>
			<MonacoInput id="base64decoded"
				example="Text"
				label="Unencoded text"
				language="plaintext"
				value={result.decoded}
				copyToClipboard={true}
				onChange={value => setResult(encoder.encode(value))}
			/>
			<MonacoInput id="base64encoded"
				example={urlSafe ? "VGV4dA" : "VGV4dA=="}
				label="Encoded UTF-8 text"
				language="plaintext"
				value={result.encoded}
				invalid={result.encodingError || !result.encodingRoundtrips}
				wordWrap={true}
				copyToClipboard={true}
				onChange={value => setResult(encoder.decode(value))}
			/>
		</article>
	);
}
