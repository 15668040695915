import { useState } from "react";

import { Article } from "Components/Article";
import { MonacoInput } from "Components/MonacoEditor";

import { HtmlEncoder } from "./Encoders";

const encoder = new HtmlEncoder();

export function HtmlEncoding() {
	const [result, setResult] = useState(encoder.encode(""));

	return (
		<article>
			<Article.Header>
				<Article.Headline>HTML encoding</Article.Headline>
				<Article.Actions>
					<button className="btn btn-tinted" onClick={() => setResult(encoder.encode(""))}>Clear</button>
				</Article.Actions>
			</Article.Header>
			<MonacoInput id="htmlencoding-plain"
				example="<>"
				label="Unencoded text"
				language="html"
				value={result.decoded}
				copyToClipboard={true}
				onChange={value => setResult(encoder.encode(value))}
			/>
			<MonacoInput id="htmlencoding-encoded"
				example="&amp;lt;&amp;gt;"
				label="Encoded HTML"
				language="html"
				value={result.encoded}
				invalid={result.encodingError}
				copyToClipboard={true}
				onChange={value => setResult(encoder.decode(value))}
			/>
		</article>
	);
}
