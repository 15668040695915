import { memo } from "react";

const protocol = document.location.protocol;
const host = document.location.host;

export const SlowLoadingResourceSimulator = memo(function SlowLoadingResourceSimulator() {
	const url = `${protocol}//${host}/tools/delay`;

	return (
		<article>
			<h2 className="mb-3">Slow loading resource simulator</h2>
			<p>The server allow you to simulate almost any slow loading resource by delaying a specified amount before redirecting the browser to the actual resource.</p>
			<p>This is useful for visualizing how a site may behave if images, fonts, scripts or styles delay for some reason.</p>
			<h3>Base URL</h3>
			<p>
				<a href={url} rel="nofollow">{url}</a>
			</p>
			<h3 className="mt-4">Query parameters</h3>
			<ul>
				<li><strong>url</strong> Target url to redirect to (required)</li>
				<li><strong>delay</strong> Delay in milliseconds, min 0, max 60000 (optional, default is 5000)</li>
			</ul>
			<h3 className="mt-4">Example of slow loading image</h3>
			<p className="overflow-auto w-100">
				<code>&lt;img src=&quot;{url}?url=https://i.imgur.com/NW7oL0d.png&quot; /&gt;</code>
			</p>
		</article>
	);
});

export const SlowLoadingScriptSimulator = memo(function SlowLoadingScriptSimulator() {
	const url = `${protocol}//${host}/tools/slow.js`;

	return (
		<article>
			<h2 className="mb-3">Slow loading script simulator</h2>
			<p>The server simulates a slow loading connection by delaying before sending a response.</p>
			<p>Use this script by temporarily inserting the script tag anywhere on the page.</p>
			<p>This is useful for debugging async script loading or visualize how sites behave with slow loading blocking scripts.</p>
			<h3>URL</h3>
			<p>
				<a href={url} rel="nofollow">{url}</a>
			</p>
			<h3 className="mt-4">Query parameters</h3>
			<ul>
				<li><strong>delay</strong> Delay in milliseconds, min 0, max 60000 (optional, default is 5000)</li>
			</ul>
			<h3>Example of blocking script include</h3>
			<p className="overflow-auto w-100">
				<code>&lt;script src=&quot;{url}?delay=5000&quot;&gt;&lt;/script&gt;</code>
			</p>
		</article>
	);
});
