import { useState } from "react";
import xmlFormatter from "xml-formatter";

import { Article } from "Components/Article";
import { Input } from "Components/Input";
import { MonacoInput } from "Components/MonacoEditor";

export interface IXmlDataState {
	xmlInput: string;
	xmlInputValid: boolean;
	xmlResult: string;
	xpath: string;
	xpathValid: boolean;
}

const blankState: IXmlDataState = {
	xmlInput: "<root></root>",
	xmlInputValid: true,
	xmlResult: "<root></root>",
	xpath: "",
	xpathValid: true
};

const domParser = new DOMParser();
const xmlSerializer = new XMLSerializer();

function formatDocument(doc: Document) {
	const xml = xmlSerializer.serializeToString(doc);

	return xmlFormatter(xml, {
		collapseContent: true,
		indentation: "  ",
		throwOnFailure: false,
		whiteSpaceAtEndOfSelfclosingTag: true
	});
}

function tryParse(xml: string) {
	try {
		const doc = domParser.parseFromString(xml, "application/xml");
		if (doc.querySelector("parsererror")) {
			return null;
		}

		return doc;
	} catch {
		return null;
	}
}

function evaluateXPath(doc: Document, xpath: string) {
	const rootNode = doc.childNodes[0];

	if (!xpath) {
		return doc;
	}

	try {
		const nsResolver = doc.createNSResolver(rootNode);
		const xpathResult = doc.evaluate(xpath, rootNode, nsResolver);
		const results: Node[] = [];
		let node = xpathResult.iterateNext();

		if (!node) {
			return null;
		}

		if (node === doc) {
			return doc;
		}

		while (node) {
			results.push(node);

			node = xpathResult.iterateNext();
		}

		const newDoc = doc.cloneNode() as Document;
		newDoc.replaceChildren();

		if (results.length === 1) {
			newDoc.replaceChildren(results[0]);
		} else {
			const resultsNode = doc.createElement("results");

			for (const resultNode of results) {
				resultsNode.appendChild(resultNode);
			}

			newDoc.replaceChildren(resultsNode);
		}

		return newDoc;
	} catch {
		return null;
	}
}

function getState(xmlInput: string, xpath: string): Partial<IXmlDataState> {
	const doc = tryParse(xmlInput);
	if (!doc) {
		return {
			xmlInput,
			xmlInputValid: false
		};
	}

	const evaluatedDoc = evaluateXPath(doc, xpath);
	if (evaluatedDoc == null) {
		return {
			xmlInput,
			xmlInputValid: true,
			xpath,
			xpathValid: false
		};
	}

	return {
		xmlInput,
		xmlInputValid: true,
		xmlResult: formatDocument(evaluatedDoc),
		xpath,
		xpathValid: true
	};
}

export function XmlData() {
	const [state, setState] = useState<IXmlDataState>(blankState);

	return (
		<article>
			<Article.Header>
				<Article.Headline>XML</Article.Headline>
				<Article.Actions>
					<button className="btn btn-tinted" onClick={() => setState(blankState)}>Clear</button>
				</Article.Actions>
			</Article.Header>
			<MonacoInput id="xmldata-input"
				label="Input XML"
				language="xml"
				value={state.xmlInput}
				invalid={!state.xmlInputValid}
				copyToClipboard={true}
				onChange={xml => setState(prev => ({ ...prev, ...getState(xml, state.xpath) }))}
			/>
			<Input id="xpath"
				label="XPath"
				value={state.xpath}
				invalid={!state.xpathValid}
				type="text"
				onChange={xpath => setState(prev => ({ ...prev, ...getState(state.xmlInput, xpath) }))}
			/>
			<MonacoInput id="xmldata-output"
				label="Output XML"
				language="xml"
				value={state.xmlResult}
				copyToClipboard={true}
				readOnly={true}
			/>
		</article>
	);
}
